import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {LoginComponent} from './login.component';
import {LucideAngularModule} from 'lucide-angular';
import {Icons} from '../shared/icons';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    SharedModule,
    Icons
  ],
  exports: [
    LoginComponent
  ],
  providers: [
  ]
})
export class LoginModule { }
