import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationData} from '@directus/sdk';
import {Router} from '@angular/router';
import {TokenStorageService} from '../shared/service/token.service';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private localstorage: TokenStorageService,
    private service: ProxyDynamicService,
    private noti: NzNotificationService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(''),
    });
  }

  async onLogin() {
    if (this.form.valid) {
      await this.service.login(this.form.controls['userName'].value, this.form.controls['password'].value).then((res: AuthenticationData) => {
        this.localstorage.saveToken(res.access_token || '');
        this.localstorage.saveRefreshToken(res.refresh_token || '');
        this.localstorage.saveExpireAt(res.expires_at?.toString() || '');
        this.router.navigate(['/']);
      }).catch((err: Error) => {
        this.noti.error(this.translate.instant('error.login'), '');
      });
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }
}
