<div nz-row>
  <div nz-col nzSpan="8" style="background: var(--bs-white); height: 100vh; justify-content: center; display: flex; align-items: center;">
    <form [formGroup]="form" (ngSubmit)="onLogin()" class="w-50">
      <h2 class="text-w-600 text-center">{{'login' | translate}}</h2>
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col nzSpan="24">
          <label>Tên tài khoản <span class="text-w-500 text-red">*</span></label>
          <input class="form-control" nz-input id="userName" formControlName="userName" placeholder="Nhập tài khoản">
        </div>
        <div nz-col nzSpan="24">
          <label>Mật khẩu <span class="text-w-500 text-red">*</span></label>
          <input class="form-control" nz-input id="password" type="password" formControlName="password" placeholder="Nhập mật khẩu">
        </div>
<!--        <div nz-col nzSpan="24">-->
<!--          <label nz-checkbox formControlName="remember">Remember</label>-->
<!--        </div>-->
        <button class="form-control btn btn-primary" style="line-height: 1;" nz-col nzSpan="24" type="submit">{{'login' | translate}}</button>
      </div>
    </form>
  </div>
  <div nz-col nzSpan="16">
    <img alt="" [src]="'/assets/images/login.png'" style="width: 100%; height: 100vh">
  </div>
</div>
